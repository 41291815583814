import React, { useEffect, useState, useRef } from "react"
import { navigate } from "gatsby"
import { Link } from "~components/linkWithPreviousUrl/linkWithPreviousUrl"


/* Import Page Component(s) */
import ProfilePage from "~components/pages/profile/profilePage"
// import VipPage from "~components/pages/vip/vipPage/vipPage"
// import RegisterForm from "~components/pages/vip/registerForm/registerForm"
// import LoginForm from "~components/pages/vip/loginForm/loginForm"

import ApplicationForm from '~components/pages/profile/applicationForm'



const Apply = ({ location }) => {
  // console.log(location)
  return (
    <ProfilePage title="VIP" header={"VIP"} location={location}>
      <ApplicationForm passwordField={true} username={'Jane Smith'} />
    </ProfilePage>
  )
}

export default Apply
